import './Carousel.scss';
import {
	Image,
} from '../../Components';
import React from 'react';

export class Carousel extends React.Component {

	constructor(props) {

		super(props);

		this.state = ({
			active: 0,
		});

		this.cycleImages = this.cycleImages.bind(this);
	}

	componentDidMount = () => {
		const {
			delay = 6,
		} = this.props;
		// console.log("Carousel mounted");
		setInterval(this.cycleImages, delay * 1000);
	};

	cycleImages = () => {
		// console.log("cycleImages");
		const {
			slides,
		} = this.props;
		let {
			active,
		} = this.state;
		active++;
		const nSlides = slides.length;
		if (active > nSlides - 1) active = 0;
		this.setState({
			active: active,
		});
		// console.log("nSlides", nSlides, "active", active);
	};

	render() {
		const {
			className,
			id,
			slides = [],
		} = this.props;

		const {
			active = 0
		} = this.state;

		const progressPips = [];
		const slideset = [];
		for (let i = 0; i < slides.length; i++) {
			progressPips.push(
				<li
					className={`${i === active ? 'active' : ''}`}
					key={`pips${i}`}
					onClick={() => { this.setState({ active: i }); }}
				/>
			);
			slideset.push(
				<Image
					className={`${className.replace('space-before', '')} ${i === active ? 'active' : ''}`}
					key={`slide${i}`}
					src={slides[i].src}
					thumb={slides[i].thumb}
					title={slides[i].title}
				/>
			);
		}
		const attributes = {};
		if (id) attributes.id = id;

		return (
			<div
				className={`carousel ${className} ${slides.length > 7 ? 'compact' : ''}`}
				{...attributes}
			>
				<div className={`images ${className.replace('centre', '').replace('space-before', '')}`}>
					{slideset}
					<div className={`caption`}>
						<ul>{progressPips}</ul>
						<span>{slides[active].title}</span>
					</div>
				</div>
			</div>
		);
	}
}
