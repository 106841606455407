import './Heading.scss';
import React from 'react';

export class Heading extends React.PureComponent {

	render() {
		const {
			className = '',
			level = 1,
			name,
			title,
		} = this.props;

		const Heading = `h${level}`;

		return (
			<>
				{name ? <a className={`reference`} name={name}>{name}</a> : null}
				<Heading className={`heading ${className}`}>
					{title}
				</Heading>
			</>
		);
	}
}
