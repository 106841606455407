import './Header.scss';
import {
	Link
} from "react-router-dom";
import React from 'react';

export class Header extends React.PureComponent {

	render() {
		return (
			<Link to="/">
				<header>

					<h1>Hidden Cambridge</h1>
					<h2>Beyond the Picture Postcards</h2>

				</header>
			</Link>
		);
	}
}

