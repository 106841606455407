import './App.scss';
import {
	BrowserRouter,
	Link,
	Route,
	Routes,
} from "react-router-dom";
import {
	Carousel,
	Figure,
	Footer,
	Header,
	Heading,
	Image,
} from './Components';
import React from 'react';
const today = new Date();
const strYear = today.getFullYear();
const strMonth = String(today.getMonth()).padStart(2, '0');

class App extends React.PureComponent {
	render() {
		return (
			<BrowserRouter>
				<div className={`app`}>
					<Header />

					<div className='content'>
						<Routes>
							<Route path='/contact' element={<Contact />} />
							<Route path='/' element={<Main />} />
						</Routes>
					</div>{/* .content */}
					<Footer />
				</div>{/* .app */}
			</BrowserRouter>
		);
	}
}
class Contact extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Contact Information`} name={`contact`} />

				<p>Please <Link to="/contact">get in touch</Link> if you have great photos that you would like to feature on the site with your name credited and a link to your website.</p>

				<p>If you would like to promote your Cambridge based business on the site, please <Link to="/contact">get in touch</Link>.</p>

				<p>Please email me at: <a href={`mailto:hiddencambridge_${strYear - 2010}${strMonth}@bagnall.co.uk`}>hiddencambridge<span>&#64;</span>bagnall.co.uk</a></p>
			</>
		);
	}
};

class Main extends React.PureComponent {
	render() {
		return (
			<>
				<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1529392147643431"
					crossOrigin="anonymous"></script>
				<Carousel
					className={`centre`}
					id={`mainCarousel`}
					slides={[
						{
							src: require(`./images/kings-college-chapel-in-snow.jpg`),
							thumb: require(`./images/thumbs/kings-college-chapel-in-snow.jpg`),
							title: `Kings College Chapel in snow`,
						},
						{
							src: require(`./images/kings-college-chapel-from-great-st-marys-tower.jpg`),
							thumb: require(`./images/thumbs/kings-college-chapel-from-great-st-marys-tower.jpg`),
							title: `Kings College Chapel from Great St. Mary's Tower`,
						},
						{
							src: require(`./images/kings-college-chapel-in-snow-dusk.jpg`),
							thumb: require(`./images/thumbs/kings-college-chapel-in-snow-dusk.jpg`),
							title: `Kings College Chapel in snow at dusk`,
						},
						{
							src: require(`./images/kings-college-chapel-summer.jpg`),
							thumb: require(`./images/thumbs/kings-college-chapel-summer.jpg`),
							title: `Kings College Chapel in summer`
						}
					]}
				/>

				<p>This is the picture postcard view of Cambridge. For the most part, this is what visitors expect to see when they
					come to Cambridge. This website is here to help you to see beyond the picture postcard images, and see the things
					you would otherwise not notice or miss.</p>

				<p>If you have nice (unfiltered) photos, or features for the site, please <Link to="/contact">get in touch</Link>. I'm
					happy to credit your photo if you would like to contribute.</p>

				<Map />

				<Cam />

				<Cycling />

				<PublicArt />

				<LookUp />

				<LookDown />

				<History />

				<TimeOfYear />

				<TimeOfDay />

				<VantagePoints />

				<Darkside />

				<Museums />

				<Chronophage />

				<DNA />

				<LeperChapel />

				<LloydsBank />

				<LowerParkStreet />

				<GasLights />

				<Hobsons />

				<Almshouses />

				<Heading level={2} title={`Just Beyond`} name={`justbeyond`} />

				<p>You don't have to travel far to find delights and mysteries beyond Cambridge, from the island of Ely to the giant
					of Histon.</p>

				<Woodcock />

				<Giant />

			</>
		);
	}
};

class Map extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Guide Map`} name={`map`} />
				<iframe
					className={`map frame`}
					id={`HiddenCambridgeMap`}
					src="https://www.google.com/maps/d/embed?mid=18A2JuaeChkk8WGvhOewC0pq0ud4&ehbc=2E312F"
					title={`Hidden Cambridge Map`}
				/>
			</>
		);
	}
};

class Cam extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`The River Cam`} name={`cam`} />

				<p>The river Cam gives Cambridge its name and curls around Cambridge to the West and to the North. It is a busy river
					with punting on the backs and upstream towards Grantchester, separated from rowing, houseboats and holiday craft
					below	Jesus Lock.</p>

				<p>In the area called "The Backs", it passes by King's College Chapel, the most famous picture postcard view of
					Cambridge. Downstream it passes under the A14 and winds towards Ely and beyond. By Midsummer Common, it is lined
					along the North side by boathouses for the many University and Town rowing clubs, with keen rowers out on the river
					at all times of day and in the worst weather too.</p>

				<Figure
					className={`centre`}
					src={require(`./images/boathouses-midsummer-common.jpg`)}
					thumb={require(`./images/thumbs/boathouses-midsummer-common.jpg`)}
					title={`Boathouses on Midsummer Common`}
				/>

				<Figure
					className={`max400 left`}
					src={require(`./images/swans-outside-goldie-boathouse.jpg`)}
					thumb={require(`./images/thumbs/swans-outside-goldie-boathouse.jpg`)}
					title={`Swans settle down for the night outside the Goldie Boathouse`}
				/>

				<Figure
					className={`left max400 clearleft`}
					src={require(`./images/the-rollers.jpg`)}
					thumb={require(`./images/thumbs/the-rollers.jpg`)}
					title={`The 'Rollers`}
				/>

				<p>Some punts can transfer between the backs and the stretch out by Grantchester Meadows, by means of " the rollers". A
					punt is generally, very heavy, being made of solid hardwood and it takes several people to manhandle one up and over
					the rollers opposite the Doubletree Hotel. An easier transition is getting a punt back down, but there's a trick to
					it, make sure someone is in the punt (and not at the front or it will become a submarine) and make sure they have a
					pole with them to punt back to shore to pick up the ones who heaved it over the rollers. Safer though to just have
					enough muscle to lower it carefully down the rollers to the waters edge and probably better if you want to get your
					deposit back.</p>

				<Figure
					className={`right max400`}
					src={require(`./images/kingfisher.jpg`)}
					thumb={require(`./images/thumbs/kingfisher.jpg`)}
					title={`Kingfisher by the Cam`}
				/>

				<p className="clearleft">Upstream, beyond Grantchester, the Cam plays host to Kingfishers and Otters. There are even
					Kingfishers neesting downstream within the city.</p>
			</>
		);
	}
};

class Cycling extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`The Cycling City?`} name={`cycling`} />

				<p>Cambridge used to have the reputation (along with Oxford and York) as a cycling city. Sadly in {strYear} that's not
					really the case.</p>

				<p>With some of the most dangerous roads in the country, Cambridgeshire is no longer a pleasant and safe place to
					cycle.</p>

				<p>You may find fewer craters on the moon than you find on the roads and cycle lanes of Cambridgeshire, not just
					shallow puddles, but deep holes in the road that will damage bike wheels and throw an unwary rider off their bike,
					possibly into the path of a vehicle.</p>

				<p>It is no longer pleasant to cycle for recreation as the rider has to scan the road constantly for dangerous surface
					damage.</p>

				<p>In addition, there are road users of all types, drivers and cyclists, who are dangerous in their use of the roads
					with no consideration for others and little care about the potential life-changing consequences of their actions.</p>

				<p>And finally, something that has troubled Cambridge for decades is cycle theft. I would not recommend buying a new
					bike as even with the most secure D-locks, it is a potential target for the scumbags who thieve.</p>

			</>
		);
	}
};

class PublicArt extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Public art`} name={`art`} />

				<Heading level={3} title={`Sculpture`} name={`sculpture`} />

				<Figure
					className={`left max400`}
					src={require(`./images/gormley-statue.jpg`)}
					thumb={require(`./images/thumbs/gormley-statue.jpg`)}
					title={`Full height human figure by Antony Gormley.`}
				/>

				<p>Cambridge is rich. Well the colleges and businesses are and so Cambridge is also rich in the	sculptures it has to offer. Check out the <a href='http://www.cambridgesculpturetrails.co.uk' target='_blank' rel="noreferrer" >Cambridge Sculpture Trails</a>.</p>

				<p><a href='http://www.cambridgesculpturetrails.co.uk/?page_id=87&objectId=city_centre' target='_blank' rel="noreferrer" >The sculpture pictured is very well hidden. How is this a full height figure?</a></p>

				<Figure
					className={`left clear max400`}
					src={require(`./images/playful-seating-for-midsummer-lawn.jpg`)}
					thumb={require(`./images/thumbs/playful-seating-for-midsummer-lawn.jpg`)}
					title={`Playful Seating for Midsummer Lawn`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/john-lewis-sculpture.jpg`)}
					thumb={require(`./images/thumbs/john-lewis-sculpture.jpg`)}
					title={`A sculpture on the wall of John Lewis, featured in the sculpture trail.`}
				/>

				<p className="clearleft">Also, check out <a
					href="https://futurecity.co.uk/portfolio/the-barnwell-sentence/"
					target="_blank" rel="noreferrer">The Barnwell Sentence</a> and
				<a href="https://futurecity.co.uk/portfolio/playful-seating-and-terrace/">Playful seating for midsummer lawn</a> pictured here.</p>

				<Heading level={3} title={`Sculpture/Mural?`} name={`glassriver`} />


				<Carousel
					className={`left max600`}
					slides={[
						{
							src: require(`./images/glass_river1.jpg`),
							thumb: require(`./images/thumbs/glass_river1.jpg`),
							title: `Glass artwork representing river Cam and bridges.`,
						},
						{
							src: require(`./images/glass_river2.jpg`),
							thumb: require(`./images/thumbs/glass_river2.jpg`),
							title: `Glass artwork representing river Cam and bridges.`,
						},
						{
							src: require(`./images/glass_river3.jpg`),
							thumb: require(`./images/thumbs/glass_river3.jpg`),
							title: `Glass artwork representing river Cam and bridges.`,
						},
						{
							src: require(`./images/glass_river4.jpg`),
							thumb: require(`./images/thumbs/glass_river4.jpg`),
							title: `Glass artwork representing river Cam and bridges.`,
						},
					]}
				/>
				<p>I'm not sure if this is a mural or a sculpture? It's a glass artwork representing the river Cam and its bridges. By Issam Kourbaj and
					Richard Bray.
					Located on the corner of Hertford Street and Chesterton Road (See <a href=' #map'>map</a>)</p>


				<Heading level={3} title={`Murals`} name={`murals`} />

				<Carousel
					className={`right max600`}
					slides={[
						{
							src: require(`./images/newmarket-road-elizabeth-way-subway-mural-1.jpg`),
							thumb: require(`./images/newmarket-road-elizabeth-way-subway-mural-1.jpg`),
							title: `Newmarket road subway mural`,
						},
						{
							src: require(`./images/newmarket-road-elizabeth-way-subway-mural-2.jpg`),
							thumb: require(`./images/newmarket-road-elizabeth-way-subway-mural-2.jpg`),
							title: `Newmarket road subway mural`,
						},
						{
							src: require(`./images/newmarket-road-elizabeth-way-subway-mural-3.jpg`),
							thumb: require(`./images/newmarket-road-elizabeth-way-subway-mural-3.jpg`),
							title: `Newmarket road subway mural`,
						},
					]}
				/>
				<p>These marvelous murals are found in the underpasses at the junction of East Road and Newmarket Road.</p>

				<Heading level={3} title={`Cows`} name={`cows`} />

				<p>This should possibly should be in the <a href='#timeofyear'>Time of Year</a> section, but these decorated cows,
					(possibly inspired by the Red Poll cows on Midsummer Common in Summer) made a
					colourful trail when they appeared on the streets, parks and in shopping centres of Cambridge (and on Midsummer Common).</p>

				<Carousel
					className={`centre max1000 cows`}
					slides={[
						{
							src: require(`./images/cows/20210710_141305.jpg`),
							thumb: require(`./images/thumbs/20210710_141305.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210710_141349.jpg`),
							thumb: require(`./images/thumbs/20210710_141349.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210710_141433.jpg`),
							thumb: require(`./images/thumbs/20210710_141433.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210710_143113.jpg`),
							thumb: require(`./images/thumbs/20210710_143113.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210710_144632.jpg`),
							thumb: require(`./images/thumbs/20210710_144632.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210715_174759.jpg`),
							thumb: require(`./images/thumbs/20210715_174759.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210715_175607.jpg`),
							thumb: require(`./images/thumbs/20210715_175607.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210718_174042.jpg`),
							thumb: require(`./images/thumbs/20210718_174042.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210721_183523.jpg`),
							thumb: require(`./images/thumbs/20210721_183523.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210721_183622.jpg`),
							thumb: require(`./images/thumbs/20210721_183622.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_120604.jpg`),
							thumb: require(`./images/thumbs/20210727_120604.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_120845.jpg`),
							thumb: require(`./images/thumbs/20210727_120845.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_124707.jpg`),
							thumb: require(`./images/thumbs/20210727_124707.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_125008.jpg`),
							thumb: require(`./images/thumbs/20210727_125008.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_130140.jpg`),
							thumb: require(`./images/thumbs/20210727_130140.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_131548.jpg`),
							thumb: require(`./images/thumbs/20210727_131548.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_131721.jpg`),
							thumb: require(`./images/thumbs/20210727_131721.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_131925.jpg`),
							thumb: require(`./images/thumbs/20210727_131925.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_132311.jpg`),
							thumb: require(`./images/thumbs/20210727_132311.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210727_132356.jpg`),
							thumb: require(`./images/thumbs/20210727_132356.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210821_151423.jpg`),
							thumb: require(`./images/thumbs/20210821_151423.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210821_151441.jpg`),
							thumb: require(`./images/thumbs/20210821_151441.jpg`),
							title: `Cambridge cows`,
						},
						{
							src: require(`./images/cows/20210830_123019.jpg`),
							thumb: require(`./images/thumbs/20210830_123019.jpg`),
							title: `Cambridge cows`,
						},
					]}
				/>
			</>);
	}
}


class LookDown extends React.PureComponent {
	render() {
		return (
			<div id='lookdown'>
				<Heading level={2} title={`Look down!`} name={`lookdown`} />

				<p>You can also miss much of "Hidden Cambridge" if you never look down!
					There's a new phenomenon on the streets of Cambridge <a href='https://www.dinkydoors.co.uk/' rel='noreferrer' target='_blank'>Dinky Doors</a>! Visit <a href='https://www.dinkydoors.co.uk/' rel='noreferrer' target='_blank'>the website</a> to purchase a map or download the app.</p>

				<Carousel
					className={`centre max1000`}
					slides={[
						{
							src: require(`./images/dinkydoors/1.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/1.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/2.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/2.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/3.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/3.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/4.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/4.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/5.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/5.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/6.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/6.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/7.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/7.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/8.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/8.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/9.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/9.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/10.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/10.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/11.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/11.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/12.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/12.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/13.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/13.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/14.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/14.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/15.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/15.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/16.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/16.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/17.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/17.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/18.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/18.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/19.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/19.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/20.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/20.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/21.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/21.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/22.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/22.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/23.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/23.jpg`),
							title: `Dinky Doors`,
						},
						{
							src: require(`./images/dinkydoors/24.jpg`),
							thumb: require(`./images/thumbs/dinkydoors/24.jpg`),
							title: `Dinky Doors`,
						},
					]}
				/>

			</div>
		);
	}
};


class LookUp extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Look up!`} name={`lookup`} />

				<p>You can miss much of "Hidden Cambridge" if you never look up! From the chimneys along Downing Street, once an indication of
					wealth, to a lighthouse like lantern feature or cupola on the back of a building on Park Street.</p>

				<p>I challenge you to look up and find the components of the decorations in the header of this web site in Cambridge.</p>

				<Figure
					className={`left max400`}
					src={require(`./images/chimneys-downing-street.jpg`)}
					thumb={require(`./images/chimneys-downing-street.jpg`)}
					title={`Ornate, tall chimneys on Downing Street.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/tall-chimneys-on-trinity-lane.jpg`)}
					thumb={require(`./images/thumbs/tall-chimneys-on-trinity-lane.jpg`)}
					title={`More tall chimneys on Trinity Lane.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/cricketer weather vane on hobbs pavillion.jpg`)}
					thumb={require(`./images/thumbs/cricketer weather vane on hobbs pavillion.jpg`)}
					title={`Cricketer weather vane on Hobbs Pavillion.`}
				/>

				<Figure
					className={`left max400`}
					src={require(`./images/gargoyle on kings college chapel.jpg`)}
					thumb={require(`./images/thumbs/gargoyle on kings college chapel.jpg`)}
					title={`Gargoyle on Kings College chapel.`}
				/>


				<Figure
					className={`right max400 clearboth`}
					src={require(`./images/cupola.jpg`)}
					thumb={require(`./images/thumbs/cupola.jpg`)}
					title={`Like a lighthouse in a city centre, an unusual cupola.`}
				/>

				<p className="clearleft">This cupola, best viewed from Clarendon Street, is to the rear of an old house at 22
					Parkside which now belongs to Emmanuel College.</p>

				<p>Amanda Goode, the Emmanuel College Archivist, kindly gave me this information: "I am afraid I cannot tell you
					a great deal about 22 Parkside,	as the College only acquired it fairly recently and we do not have the original					architect's drawings, etc. It is a listed building and the listing					entry describes the lantern tower as 'Wooden cupola at rear',					without giving any further details. The earliest deed relating to the property					in the College Archives is dated 1913,					when it was known as 'Inveruglas House', and sold by William B Redfearn to Dr					John Charles William Graham. The deed states that the property had
					been erected by the vendor on land acquired in 1873."</p>
				<p>If anyone has a photo of the inside of this feature, I'd love to see it!</p>

				<Heading level={3} title={`King Henry's Chair Leg`} name={`kinghenrychairleg`} />

				<Figure
					className={`left max400`}
					src={require(`./images/chair leg 1.jpg`)}
					thumb={require(`./images/thumbs/chair leg 1.jpg`)}
					title={`King Henry VIII holding a sceptre?`}
				/>
				<Figure
					className={`right max400`}
					src={require(`./images/chair leg 2.jpg`)}
					thumb={require(`./images/thumbs/chair leg 2.jpg`)}
					title={`No! It' s a chair leg`}
				/>

				<p className={`clearleft`}>Walking by the gate to Trinity college, you could be forgiven for thinking King Henry VIII
					is holding a sceptre and an orb. Look closer.&nbsp;
				<a href='https://www.varsity.co.uk/news/2757' target='_blank' rel="noreferrer">Story behind the chair leg</a>.</p>
				<p>Unfortunatley this lovely old tradition has now been replaced <a href='https://www.bbc.co.uk/news/uk-england-cambridgeshire-67420099' target="_blank" rel="noreferrer">Trinity College statue's chair leg replaced with sceptre</a></p>
			</>
		);
	}
};

class History extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Hidden by History`} name={`history`} />

				<p>This section is mostly for sights that have disappeared since I first moved to Cambridge, some, like this general
					store, John Cook &amp; Bros Ltd, were already like time capsules but now sadly gone, though the building remains at
					the corner of Prospect Street and Eden Street.</p>

				<Carousel
					className={`centre max600`}
					slides={[
						{
							src: require(`./images/john-cook-and-bros-1.jpg`),
							thumb: require(`./images/john-cook-and-bros-1.jpg`),
							title: `John Cook & Bros Ltd`,
						},
						{
							src: require(`./images/john-cook-and-bros-2.jpg`),
							thumb: require(`./images/john-cook-and-bros-2.jpg`),
							title: `John Cook & Bros Ltd`,
						},
						{
							src: require(`./images/john-cook-and-bros-3.jpg`),
							thumb: require(`./images/john-cook-and-bros-3.jpg`),
							title: `John Cook & Bros Ltd`,
						},
						{
							src: require(`./images/john-cook-and-bros-4.jpg`),
							thumb: require(`./images/john-cook-and-bros-4.jpg`),
							title: `John Cook & Bros Ltd`,
						},
						{
							src: require(`./images/john-cook-and-bros-5.jpg`),
							thumb: require(`./images/john-cook-and-bros-5.jpg`),
							title: `John Cook & Bros Ltd`,
						},
					]}
				/>

				<Carousel
					className={`left max400 space-before`}
					delay={10}
					slides={[
						{
							src: require(`./images/h-gee.jpg`),
							thumb: require(`./images/h-gee.jpg`),
							title: `H. Gee 2014`,
						},
						{
							src: require(`./images/h-gee2021.jpg`),
							thumb: require(`./images/h-gee2021.jpg`),
							title: `H. Gee 2021`,
						},
					]}
				/>

				<p className={`space-before`}>Others were Cambridge institutions, like H. Gee. on Mill Road. A one stop shop for anything
					electrical. A forerunner of Maplin & Tandy.</p>


				<p className="clear">Fitzbillie's, a famous bakery, patissery and cafe, dating back to 1920, was nearly lost after a
					fire, but <a href="https://www.varsity.co.uk/news/16771"
					target="_blank" rel="noreferrer">some hero stepped up to preserve this heritage</a>!</p>

				<Figure
					className={`left max400`}
					src={require(`./images/fitzbillies.jpg`)}
					thumb={require(`./images/thumbs/fitzbillies.jpg`)}
					title={`Fitzbillies`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/stourbridge-common.jpg`)}
					thumb={require(`./images/thumbs/stourbridge-common.jpg`)}
					title={`View from the Pike and Eel`}
				/>

				<p className="clear">The Pike and Eel pub, latterly known also as the Penny Ferry, was one of my favourite pubs in
					Cambridge, simply because of this view in summer. The pub itself was unremarkable and suffered from troubles from
					time to time. In the end, Greene King decided to close it and sell the land for development. Now the only people
					to enjoy this 180&deg; view of Stourbridge Common are the new owners of the houses built on the plot of the old pub.
					Another pub, one of so many, lost forever.</p>

				<Heading level={3} title={`Covid 19`} name={`covid19`} />

				<Figure
					className={`left max400 clearboth`}
					src={require(`./images/chronophage_c19.jpg`)}
					thumb={require(`./images/thumbs/chronophage_c19.jpg`)}
					title={`Chronophage during Covid 19.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/kings_parade_c19.jpg`)}
					thumb={require(`./images/thumbs/kings_parade_c19.jpg`)}
					title={`King's Parade during Covid 19.`}
				/>

				<p>A section I'd rather	didn't exist is	a dark chapter in history when the pandemic	of Covid
					19 is taking so many lives and producing side effects that will last for years.</p>

				<p>Cambridge is generally a bustling, tourist hotspot, and as I write, it is getting back to
					being just that once more.</p>

				<p>In the early	days of	lockdown, I was	reluctant to go into the city centre at all,
					but on this rainy day I capitalised on the low number of people	due to the weather
					and had a great walk around. In	addition, the wet streets and buildings gave me	the kind
					of photographs that I don't normally have in my collection. You would not, for example
					normally be able to take a photograph of the Chronophage from a distance, as people
					are	usually	crowded	around it. And <a href='#lowerparkstreet'>Lower Park Street
						(mentioned more	later)</a>, which I	have tried to get an unobstructed photograph
					of without success for some	time, still eludes me because of that single car!</p>
				<Figure
					className={`left max400`}
					src={require(`./images/lower_park_street_c19.jpg`)}
					thumb={require(`./images/thumbs/lower_park_street_c19.jpg`)}
					title={`Lower Park Street during Covid 19.`}
				/>
				<Figure
					className={`right max400`}
					src={require(`./images/trinity_c19.jpg`)}
					thumb={require(`./images/thumbs/trinity_c19.jpg`)}
					title={`Trinity College during Covid 19.`}
				/>
			</>
		);
	}
};

class TimeOfYear extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Time of Year`} name={`timeofyear`} />

				<p>This gable end of a house in Orchard Street is just a tangle of wood in winter time, but pass by
					at the right time in spring/summer and it is a waterfall of wysteria.</p>

				<Carousel
					className={`centre max600`}
					slides={[
						{
							src: require(`./images/orchard-street-winter.jpg`),
							thumb: require(`./images/thumbs/orchard-street-winter.jpg`),
							title: `Orchard Street Wysteria`,
						},
						{
							src: require(`./images/orchard-street-summer.jpg`),
							thumb: require(`./images/thumbs/orchard-street-summer.jpg`),
							title: `Orchard Street Wysteria`,
						},
					]}
				/>
				<Figure
					className={`left space-before max400`}
					src={require(`./images/orchard-street.jpg`)}
					thumb={require(`./images/thumbs/orchard-street.jpg`)}
					title={`Orchard Street itself is an attractive architectural site`}
				/>


				<Heading level={3} title={`Summer`} name={`summer`} />

				<Figure
					className={`centre max1000`}
					src={require(`./images/redpoll-cows-graze-midsummer-common.jpg`)}
					thumb={require(`./images/thumbs/redpoll-cows-graze-midsummer-common.jpg`)}
					title={`Redpoll cows graze Midsummer Common`}
				/>

				<Heading level={3} title={`Winter`} name={`winter`} />

				<Figure
					className={`left max400`}
					src={require(`./images/bridge-of-sighs-in-winter.jpg`)}
					thumb={require(`./images/thumbs/bridge-of-sighs-in-winter.jpg`)}
					title={`Bridge of Sighs in Winter`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/snowy-avenue-trinity-college.jpg`)}
					thumb={require(`./images/thumbs/snowy-avenue-trinity-college.jpg`)}
					title={`Snowy avenue, Trinity College`}
				/>

				<Figure
					className={`left max400`}
					src={require(`./images/snowy-bicycle.jpg`)}
					thumb={require(`./images/thumbs/snowy-bicycle.jpg`)}
					title={`Snowy bicycle`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/snowy-punts.jpg`)}
					thumb={require(`./images/thumbs/snowy-punts.jpg`)}
					title={`Snowy punts`}
				/>

				<p className={`clear`}>Some sights are totally unexpected, for example this appearance of a
					hovercraft on Parkers Piece. My Son and I were fortunate to take a ride in it. </p>

				<video
					className={`max600 centre frame`}
					controls
				>
					<source
						src={require(`./images/hovercraft-on-parkers-piece.mp4`)}
						type={`video/mp4`} />
					Your browser does not support the video tag.
				</video>
			</>
		);
	}
};

class TimeOfDay extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Time of Day`} name={`timeofday`} />

				<p>Aspects of Cambridge are hidden unless you're there at the right time of day.</p>

				<Heading level={3} title={`Cambridge Early Morning`} name={`morning`} />

				<Figure
					className={`left max400`}
					src={require(`./images/dewy-morning-parkers-piece.jpg`)}
					thumb={require(`./images/thumbs/dewy-morning-parkers-piece.jpg`)}
					title={`A dewy morning on Parker's Piece`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/reality-checkpoint-early-morning.jpg`)}
					thumb={require(`./images/thumbs/reality-checkpoint-early-morning.jpg`)}
					title={`Reality Checkpoint early morning`}
				/>

				<p className="clearleft">Reality Checkpoint, so named as it marks an imaginary border between "town and gown".
				</p>
				<Figure
					className={`left max400`}
					src={require(`./images/misty-midsummer-common.jpg`)}
					thumb={require(`./images/thumbs/misty-midsummer-common.jpg`)}
					title={`A misty morning on Midsummer Common`}
				/>

				<Heading level={3} title={`Cambridge Evening`} name={`evening`} />

				<Figure
					className={`left max400`}
					src={require(`./images/sunset-midsummer-common-2.jpg`)}
					thumb={require(`./images/thumbs/sunset-midsummer-common-2.jpg`)}
					title={`Sunset over Midsummer Common`}
				/>

				<Figure
					className={'right max400'}
					src={require(`./images/sunset-midsummer-common-1.jpg`)}
					thumb={require(`./images/thumbs/sunset-midsummer-common-1.jpg`)}
					title={`Midsummer Common sunset from the boathouses`}
				/>

				<Figure
					className={`centre clear max800`}
					src={require(`./images/river-cam-at-night-1.jpg`)}
					thumb={require(`./images/thumbs/river-cam-at-night-1.jpg`)}
					title={`Flat calm river Cam in the evening`}
				/>

				<Heading level={3} title={`Cambridge at Night`} name={`night`} />

				<Carousel
					className={`left max400`}
					slides={[
						{
							src: require(`./images/riverside-bridge-at-night-1.jpg`),
							thumb: require(`./images/thumbs/riverside-bridge-at-night-1.jpg`),
							title: `Riverside bridge at night`,
						},
						{
							src: require(`./images/riverside-bridge-at-night-2.jpg`),
							thumb: require(`./images/thumbs/riverside-bridge-at-night-2.jpg`),
							title: `Riverside bridge at night`,
						},
					]}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/bike-lights-leave-a-trail-by-the-moorings-on-midsummer-common.jpg`)}
					thumb={require(`./images/thumbs/bike-lights-leave-a-trail-by-the-moorings-on-midsummer-common.jpg`)}
					title={`Bike lights leave a trail by the river Cam`}
				/>
			</>
		);
	}
};

class VantagePoints extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Vantage Points`} name={`vantagepoints`} />


				<p>On visiting a new place, it's often nice (if you have a head for heights), to take a look from a high vantage point.</p>

				<p>Here are a few in Cambridge, first of all the free ones, then <a href="great_st_marys">Great St. Mary's Church Tower</a>
					where you have to pay for admission.</p>

				<Heading level={3} title={`Castle Mound`} name={`castle`} />

				<Carousel
					className={`centre max600`}
					slides={[
						{
							src: require(`./images/castle-mound-1.jpg`),
							thumb: require(`./images/thumbs/castle-mound-1.jpg`),
							title: `Castle Mound`,
						},
						{
							src: require(`./images/castle-mound-2.jpg`),
							thumb: require(`./images/thumbs/castle-mound-2.jpg`),
							title: `Castle Mound`,
						},
						{
							src: require(`./images/castle-mound-information-board.jpg`),
							thumb: require(`./images/thumbs/castle-mound-information-board.jpg`),
							title: `Castle Mound Information`,
						},
					]}
					title={`Castle Mound`}
				/>

				<p>Castle mound is the motte of
					<a href='https://en.wikipedia.org/wiki/Cambridge_Castle' target='_blank' rel="noreferrer">Cambridge Castle</a>,
					built after the Norman Conquest. From the top you have a good view over Cambridge and surrounding countryside.
					If you look to the North West, Huntingdon Road looks long and straight and you'd be right if you guessed that it
					was once a roman road. There are other roman roads in the area, notably at Wandlebury. </p>

				<Image
					className={`centre max400 frame`}
					src={require(`./images/thumbs/castle-mound-smallworld.jpg`)}
					thumb={require(`./images/thumbs/castle-mound-smallworld.jpg`)}
				/>

				<iframe
					className={`space-before view360 frame centre`}
					src={`https://www.hiddencambridge.uk/castle-mound/`}
					title={`Castle Mound`}
				/>

				<Heading level={3} title={`John Lewis`} name={`johnlewis`} />

				<Figure
					className={`centre`}
					src={require(`./images/john_lewis_view.jpg`)}
					thumb={require(`./images/thumbs/john_lewis_view.jpg`)}
					title={`View from upper floor of John Lewis`}
				/>
				<p>The upper floors of the John Lewis store in Cambridge give a good view over the city, though not
					as spectacular as the views offered by <a href="#great_st_marys">Great St. Mary's Church Tower</a>. </p>

				<Heading level={3} title={`Grafton Centre`} name={`grafton`} />

				<p>The upper car park level offers views over surrounding streets and the Midsummer Common and
					East Road areas. </p>

				<Figure
					className={`centre max400`}
					src={require(`./images/cupola wilco.jpg`)}
					thumb={require(`./images/thumbs/cupola wilco.jpg`)}
					title={`Cupola atop Wilco in Fitzroy Street`}
				/>
				<Figure
					className={`left max400`}
					src={require(`./images/atrium wilco.jpg`)}
					thumb={require(`./images/thumbs/atrium wilco.jpg`)}
					title={`Atrium in Wilco in Fitzroy Street`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/wilco.jpg`)}
					thumb={require(`./images/thumbs/wilco.jpg`)}
					title={`Wilco in Fitzroy Street`}
				/>

				<p>From here you might see the cupola atop a much overlooked architectural treasure, currently home
					to Wilco home stores on Fitzroy Street. Step inside and you'll find a magnificent atrium.
					If I were a millionaire I'd love to make it into a home! </p>

				<Heading level={3} title={`Great St. Mary' s Church Tower`} name={`stmarys`} />

				<Figure
					className={`centre`}
					src={require(`./images/cambridge-market-from-great-st-marys-tower-panorama.jpg`)}
					thumb={require(`./images/thumbs/cambridge-market-from-great-st-marys-tower-panorama.jpg`)}
					title={`Cambridge Market from Great St. Marys tower`}
				/>

				<Figure
					className={`centre`}
					src={require(`./images/kings-college-from-great-saint-marys-tower.jpg`)}
					thumb={require(`./images/thumbs/kings-college-from-great-saint-marys-tower.jpg`)}
					title={`King' s College Chapel from Great St. Marys tower`}
				/>

				<Heading level={3} title={`Aerial Views`} name={`aerial`} />

				<p>A very pleasant way to see Cambridge is to take a flight, or a trial flying lesson. </p>

				<Figure
					className={`centre max400`}
					src={require(`./images/kings-college-from-the-air.jpg`)}
					thumb={require(`./images/thumbs/kings-college-from-the-air.jpg`)}
					title={`Kings College Chapel and Cambridge Market from the air`}
				/>

				<p>Contact <a href="http://cambridgeaeroclub.com/">Cambridge Aero Club</a> or <a href="http://www.cambridgeflyinggroup.co.uk/" target='_blank' rel="noreferrer">Cambridge Flying Group</a></p>

			</>
		);
	}
};

class Darkside extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`The Darker Side of Cambridge`} name={`darkside`} />

				<Figure
					className={`left max400`}
					src={require(`./images/discarded-hyperdermic-needle.jpg`)}
					thumb={require(`./images/thumbs/discarded-hyperdermic-needle.jpg`)}
					title={`A hyperdermic carelessly discarded by a drug addict.`}
				/>

				<p>Perhaps what the tourist industry would prefer to be "Hidden Cambridge" - the Cambridge flipside.</p>

				<p>There are discarded hyperdermic needles from drug addicts which pose a danger to anyone, toddlers
					especially if they pick them up or play in piles of leaves. </p>

				<Figure
					className={`left clear max400`}
					src={require(`./images/litter-strewn-on-open-spaces.jpg`)}
					thumb={require(`./images/thumbs/litter-strewn-on-open-spaces.jpg`)}
					title={`Littering.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/moorhen-nest-built-with-discarded-plastic-litter.jpg`)}
					thumb={require(`./images/thumbs/moorhen-nest-built-with-discarded-plastic-litter.jpg`)}
					title={`Moorhen nest built with discarded plastic litter.`}
				/>

				<p>Discarded chewing gum leaves dark blotches on the pavements, pretty much everywhere. I can
					understand why 			<a href="https://en.wikipedia.org/wiki/Chewing_gum_ban_in_Singapore" target="_blank" rel="noreferrer">Singapore made
						it illegal</a>. </p>

				<Figure className={`max400 left clear`}
					src={require(`./images/fly-tipping.jpg`)}
					thumb={require(`./images/thumbs/fly-tipping.jpg`)}
					title={`Fly tipping on Cutter Ferry Path.`}
				/>

				<p>People fly tip (I don't know why) and people leave packaging from their picnic in the sunny
					weather when there are bins just metres away, they also carelessly burn rectangles into the grass
					in the open spaces with disposable barbecues. It's a blight. Perhaps one year the council should
					refuse to tidy up after people and then they would have nowhere to enjoy and sit in the sunshine
					except for on piles of litter? </p>

				<p className='clear'> It is also pretty standard to encounter unwelcome grafiti. </p>

				<Figure
					className={`left max400`}
					src={require(`./images/grafiti-on-victoria-bridge.jpg`)}
					thumb={require(`./images/thumbs/grafiti-on-victoria-bridge.jpg`)}
					title={`Grafiti on Victoria Bridg.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/subway-grafiti.jpg`)}
					thumb={require(`./images/thumbs/subway-grafiti.jpg`)}
					title={`Subway grafiti.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/rough-sleeping.jpg`)}
					thumb={require(`./images/thumbs/rough-sleeping.jpg`)}
					title={`Two rough sleepers in Cambridge city centre.`}
				/>

				<p className={`clearleft`}> Homelessness is also commonplace in Cambridge with street begging and
					sleeping rough. The police recommend that if you want to help, give to the local shelter charities
				<a href="http://www.jimmyscambridge.org.uk/donate">Jimmy's</a> and
				<a href="http://wintercomfort.org.uk/donate">Wintercomfort</a>.
					Giving directly to the homeless may just fuel an addiction or make things worse for them. </p>

				<Figure
					className={`left max400`}
					src={require(`./images/shanty.jpg`)}
					thumb={require(`./images/thumbs/shanty.jpg`)}
					title={`Shanty town.`}
				/>
				<p className='clearright'>Even some residential areas of Cambridge can look like a shanty town. </p>

			</>
		);
	}
};

class Museums extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Museums`} name={`museums`} />

				<p>There are many fine museums in Cambridge, many with free entry. If you visit Cambridge on a
					rainy day and don't want to brave the weather, try one of these hidden gems. Here are just some
					of my favourites: </p>

				<Heading level={3} title={`The Museum of Zoology`} name={`zoology`} />

				<p><a href="https://www.museum.zoo.cam.ac.uk/" target="_blank" rel="noreferrer">The Museum of Zoology</a>, housed in
					the newly refurbished and renamed David Attenborough building, is a place of curiosity when you go
					beyond the iconic whale skeleton in the foyer. </p>

				<Figure
					className={`centre`}
					src={require(`./images/whale.jpg`)}
					thumb={require(`./images/thumbs/whale.jpg`)}
					title={`The Museum of Zoology`}
				/>

				<Heading level={3} title={`The Sedgwick`} name={`sedgwick`} />

				<p><a href="http://www.sedgwickmuseum.org/" target="_blank" rel="noreferrer">The Sedgwick Museum of Earth Sciences</a>
					sounds a bit dry, but trust me, it's a great place to meet dinosaurs and many other exhibits. </p>

				<p>It's also here, in the courtyard, that you'll find the Antony Gormley statue mentioned in the
					<a href="#sculptrue">sculpture</a> section! </p>

				<Heading level={3} title={`The Fitzwilliam`} name={`fitzwilliam`} />

				<Figure
					className={`right max400`}
					src={require(`./images/fitzwilliam.jpg`)}
					thumb={require(`./images/thumbs/fitzwilliam.jpg`)}
					title={`The Fitzwilliam`}
				/>

				<p><a href="https://www.fitzmuseum.cam.ac.uk/" target="_blank" rel="noreferrer">The Fitzwilliam Museum</a>, in it's
					grand building, houses treasures of art, sculpture, antiquity and rare exhibitions. </p>

				<Heading level={3} title={`The Cambridge Museum of Technology`} name={`museumoftechnology`} />

				<Figure
					className={`left max400`}
					src={require(`./images/museum-of-technology.jpg`)}
					thumb={require(`./images/thumbs/museum-of-technology.jpg`)}
					title={`The Cambridge Museum of Technology`}
				/>

				<p><a href="http://www.museumoftechnology.com/" target="_blank" rel="noreferrer">The Cambridge Museum of Technology</a>&nbsp;
					was an old sh** pumping station that rid Cambridge of its effluent in days gone by. Now with a
					lottery grant, the whole site has been refurbished and you may even be lucky enough to visit on a
					day when the steam engines are running. </p>

				<Heading level={3} title={`The Centre for Computing History`} name={`computinghistory`} />

				<p><a href="http://www.computinghistory.org.uk/" target="_blank" rel="noreferrer">The Centre for Computing History</a>&nbsp;
					is a little outside the city centre, but well worth a visit. It is itself a little hidden in an
					industrial estate. </p>

				<Heading level={3} title={`The Museum of Cambridge`} name={`museumofcambridge`} />

				<p><a href="http://www.museumofcambridge.org.uk/">The Museum of Cambridge</a> I have mentioned in the
					sections about the <a href="#histongiant">Histon Giant</a> and 			<a href="#woodcock">Elizabeth Woodcock</a>. </p>
			</>
		);
	}
};

class Chronophage extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`The Chronophage`} name={`chronophage`} />

				<Figure
					className={`left max400`}
					src={require(`./images/chronophage-1.jpg`)}
					thumb={require(`./images/thumbs/chronophage-1.jpg`)}
					title={`The Chronophage`}
				/>

				<p>This is the amazing "Chronophage" or <a href="http://www.johnctaylor.com/" target="_blank" rel="noreferrer">Corpus
					Christi Clock</a>. You could easily walk by and miss it if there are a few people in front of it.</p>

				{/* <% ' call hrefImg("images/thumbs/chronophage-2.jpg","frame left max400","title")%> */}
				<Figure
					className={`right max400`}
					src={require(`./images/midsummer-chronophage.jpg`)}
					thumb={require(`./images/thumbs/midsummer-chronophage.jpg`)}
					title={`This 'Midsummer' Chronophage was exhibited in Lion Yard for a time in 2019.`}
				/>
			</>
		);
	}
};

class DNA extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`DNA and Cambridge`} name={`dna`} />

				<p>Cambridge is famous for DNA discovery and centres of excellence for DNA research. Watson, Crick and Franklin are
					probably the most famous scientists in this field for their work though there are many more. </p>

				<p>DNA features in sculptures, cycle path markings etc. in Cambridge. </p>

				<Figure
					className={`left max400`}
					src={require(`./images/dna-fragment-great-shelford.jpg`)}
					thumb={require(`./images/thumbs/dna-fragment-great-shelford.jpg`)}
					title={`This cycle path near Great Shelford is marked with a fragment of human DNA.`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/dna-sculpture.jpg`)}
					thumb={require(`./images/thumbs/dna-sculpture.jpg`)}
					title={`DNA sculpture.`}
				/>

				<Figure
					className={`max400 clearleft left`}
					src={require(`./images/double-helix-sculpture.jpg`)}
					thumb={require(`./images/thumbs/double-helix-sculpture.jpg`)}
					title={`Double helix sculpture, Clare College`}
				/>

				<Figure
					className={`clear centre`}
					src={require(`./images/the-wellcome-trust-genome-campus.jpg`)}
					thumb={require(`./images/thumbs/the-wellcome-trust-genome-campus.jpg`)}
					title={`The Wellcome Trust Genome Campus. Just one of many genetic campuses in and around Cambridge.`}
				/>

				<Figure
					className={`max600 clear`}
					src={require(`./images/cambridge-biomedical-campus.jpg`)}
					thumb={require(`./images/thumbs/cambridge-biomedical-campus.jpg`)}
					title={`The Cambridge Biomedical Campus.`}
				/>
			</>
		);
	}
};

class LeperChapel extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`The Leper Chapel`} name={`leperchapel`} />

				<p>A leper chapel? In Cambridge? Well yes, on Newmarket Road, next to the Chisholm Trail, you'll find this bijoux,
					pretty building belying it's history</p>
				<Figure
					className={`left max400`}
					src={require(`./images/leper-chapel-1.jpg`)}
					thumb={require(`./images/thumbs/leper-chapel-1.jpg`)}
					title={`The Leper Chapel`}
				/>
				<Carousel
					className={`right max400`}
					slides={[
						{
							src: require(`./images/leper-chapel-2.jpg`),
							thumb: require(`./images/thumbs/leper-chapel-2.jpg`),
							title: `The Leper Chapel`,
						},
						{
							src: require(`./images/leper-chapel-4.jpg`),
							thumb: require(`./images/thumbs/leper-chapel-4.jpg`),
							title: `The Leper Chapel`,
						},
					]}
				/>

				<Carousel
					className={`centre max400`}
					slides={[
						{
							src: require(`./images/leper-chapel-3.jpg`),
							thumb: require(`./images/thumbs/leper-chapel-3.jpg`),
							title: `The Leper Chapel Interior`,
						},
						{
							src: require(`./images/leper-chapel-5.jpg`),
							thumb: require(`./images/thumbs/leper-chapel-5.jpg`),
							title: `The Leper Chapel Interior`,
						},
						{
							src: require(`./images/leper-chapel-6.jpg`),
							thumb: require(`./images/thumbs/leper-chapel-6.jpg`),
							title: `The Leper Chapel Interior`,
						},
					]}
				/>
			</>
		);
	}
};

class LloydsBank extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Lloyds Bank`} name={`lloydsbank`} />

				<p>This building puts me in mind of Gringot's from the Harry Potter Stories and looks grand from the outside, but I'd urge
					you to venture in to see the beautiful, rich glazed tiles adorning the walls and columns. Easily missed if you walk by.</p>

				<Figure
					className={`left max400`}
					src={require(`./images/lloyds-exterior.jpg`)}
					thumb={require(`./images/thumbs/lloyds-exterior.jpg`)}
					title={`Lloyds Bank`}
				/>
				<Figure
					className={`right max400`}
					src={require(`./images/lloyds-interior-1.jpg`)}
					thumb={require(`./images/thumbs/lloyds-interior-1.jpg`)}
					title={`Lloyds Bank`}
				/>
				<Figure
					className={`right max400`}
					src={require(`./images/lloyds-interior-2.jpg`)}
					thumb={require(`./images/thumbs/lloyds-interior-2.jpg`)}
					title={`Lloyds Bank`}
				/>
			</>
		);
	}
};

class LowerParkStreet extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Lower Park Street`} name={`lowerparkstreet`} />

				<p>As well as Orchard Street (mentioned before), Lower Park Street, close by Jesus Green, is a highly photogenic
					street of University properties which seem to be charming clones of one another with identical door colours.
					Hard to photograph without the wheely bins sadly! (See also <a href='#covid19'>the section on Covid 19</a>).</p>

				<Figure
					className={`centre`}
					src={require(`./images/thumbs/lower-park-street-panorama.jpg`)}
					thumb={require(`./images/thumbs/lower-park-street-panorama.jpg`)}
					title={`Lower Park Street`}
				/>
				<Figure
					className={`max400 left`}
					src={require(`./images/lower-park-street-2.jpg`)}
					thumb={require(`./images/thumbs/lower-park-street-2.jpg`)}
					title={`Lower Park Street`}
				/>
				<Figure
					className={`max400 right`}
					src={require(`./images/lower-park-street-5.jpg`)}
					thumb={require(`./images/thumbs/lower-park-street-5.jpg`)}
					title={`Lower Park Street`}
				/>
			</>
		);
	}
};

class GasLights extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`Working Gas Lights`} name={`gaslights`} />

				<p>Would you believe we have some preserved, working gaslights in Cambridge? Check the <a href=' #map'>map</a> for
					locations.</p>
				<Figure
					className={`max400 left clear`}
					src={require(`./images/gaslight-claremont-1.jpg`)}
					thumb={require(`./images/thumbs/gaslight-claremont-1.jpg`)}
					title={`Gas light at Claremont off Hills Road`}
				/>
				<Figure
					className={`max400 right`}
					src={require(`./images/gaslight-claremont-2.jpg`)}
					thumb={require(`./images/thumbs/gaslight-claremont-2.jpg`)}
					title={`Gas light at Claremont off Hills Road`}
				/>

				<Figure
					className={`max400 clear left`}
					src={require(`./images/gaslight-little-st-marys-lane.jpg`)}
					thumb={require(`./images/thumbs/gaslight-little-st-marys-lane.jpg`)}
					title={`Gas light at Little St. Mary's Lane`}
				/>


				<Figure
					className={`max400 right`}
					src={require(`./images/gaslight-thrifts-walk.jpg`)}
					thumb={require(`./images/thumbs/gaslight-thrifts-walk.jpg`)}
					title={`Gas light at Thrift's Walk in Chesterton`}
				/>

				<Figure
					className={`max400 right`}
					src={require(`./images/gaslight-south-green-road.jpg`)}
					thumb={require(`./images/thumbs/gaslight-south-green-road.jpg`)}
					title={`Gas light at South Green Road`}
				/>

				<p className="clearleft">There are many more at nearby Millington Road. They are all marked on the map near the top of the page.</p>

				<Figure
					className={`max400 left`}
					src={require(`./images/gaslight-willow-walk.jpg`)}
					thumb={require(`./images/thumbs/gaslight-willow-walk.jpg`)}
					title={`Gas light at Willow Walk`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/gaslights-willow-walk-3.jpg`)}
					thumb={require(`./images/thumbs/gaslights-willow-walk-3.jpg`)}
					title={`Gas lights at Willow Walk`}
				/>
			</>
		);
	}
};

class Hobsons extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={2} title={`"Hobson's Conduit`} name={`hobsonsconduit`} />

				<p><a href="http://www.trumpingtonlocalhistorygroup.org/subjects_HobsonsConduit.html" target="_blank" rel="noreferrer">Hobson's Conduit</a>&nbsp;
					is a water course (Named after Thomas Hobson), to supply clean drinking water to the centre of Cambridge, from Springs
					including the chalk springs at&nbsp;<a href='https://lnr.cambridge.gov.uk/nature_reserve/nine-wells/' target='_blank' rel="noreferrer">Nine Wells</a> in the Shelfords area.</p>

				<Figure
					className={`max400 left`}
					src={require(`./images/nine-wells-spring.jpg`)}
					thumb={require(`./images/thumbs/nine-wells-spring.jpg`)}
					title={`Nine Wells chalk spring`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/litter-at-nine-wells.jpg`)}
					thumb={require(`./images/thumbs/litter-at-nine-wells.jpg`)}
					title={`Unfortunately, Nine Wells is not immune to those who care less about the environment.`}
				/>

				<Figure
					className={`left max400`}
					src={require(`./images/obelisk-at-nine-wells.jpg`)}
					thumb={require(`./images/thumbs/obelisk-at-nine-wells.jpg`)}
					title={`Obelisk at Nine Wells`}
				/>

				<p className="clear"> Before entering Cambridge, it is an open brook. In Cambridge it can be see as open gullys by
					the side of Trumpington Street (Good luck if you park carelessly and drop a wheel in there!). Elsewhere it is
					covered but identified by decorative iron covers.</p>

				<Figure
					className={`left max400`}
					src={require(`./images/hobsons-conduit-2.jpg`)}
					thumb={require(`./images/thumbs/hobsons-conduit-2.jpg`)}
					title={`Hobson's Conduit in Trumpington Street`}
				/>

				<Figure
					className={`max400 right`}
					src={require(`./images/hobsons-conduit-route.jpg`)}
					thumb={require(`./images/thumbs/hobsons-conduit-route.jpg`)}
					title={`Hobson's Conduit, decorative iron covers in St. Andrews Street`}
				/>

				<p className="clear"> The original drinking fountain in Market Square has been moved to the corner of Trumpington
					Road and Lensfield Road (See <a href='#map'>map</a>).</p>

				<Carousel
					className={`left max400`}
					slides={[
						{
							src: require(`./images//water-fountain-1.jpg`),
							thumb: require(`./images/thumbs//water-fountain-1.jpg`),
							title: `Hobson's Conduit, drinking fountain`,
						},
						{
							src: require(`./images/water-fountain-2.jpg`),
							thumb: require(`./images/thumbs/water-fountain-2.jpg`),
							title: `Hobson's Conduit, drinking fountain`,
						},
					]}
				/>

				<Figure
					className={`max400 right`}
					src={require(`./images/water-fountain-3.jpg`)}
					thumb={require(`./images/thumbs/water-fountain-3.jpg`)}
					title={`Hobson's Conduit drinking fountain top detail`}
				/>
			</>
		);
	}
};

class Almshouses extends React.PureComponent {
	render() {
		return (
			<>
				{/* <Heading level={2} title={`Almshouses`} name={`almshouses`}/>

			<p>Almshouses <a href='https://cambridgeunitedcharities.wordpress.com/the-almshouses/#King_Street' target='_blank' rel="noreferrer">link</a>
				<a href='https://www.british-history.ac.uk/rchme/cambs/pp313-319' target='_blank' rel="noreferrer">link</a></p>

			<Figure
				className={`max400 left`}
				src={require(`./images/94-king-street.jpg`)}
				thumb={require(`./images/thumbs/94-king-street.jpg`)}
				title={`94 King Street`}
			/> */}
			</>
		);
	}
};

class Woodcock extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={3} title={`The Strange Tale of Elizabeth Woodcock`} name={`woodcock`} />

				<p>As I was cycling along the guided busway, I noticed a stone like a plinth or a memorial in a field.
					There had to be a story behind this or surely the farmer would simply move it to allow easier access with farm machinery?</p>

				<Figure
					className={`left clear max400`}
					src={require(`./images/guided-busway-elizabeth-woodcock-memorial-viewpoint.jpg`)}
					thumb={require(`./images/thumbs/guided-busway-elizabeth-woodcock-memorial-viewpoint.jpg`)}
					title={`Guided Busway`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/replacement-elizabeth-woodcock-memorial-stone.jpg`)}
					thumb={require(`./images/thumbs/replacement-elizabeth-woodcock-memorial-stone.jpg`)}
					title={`Memorial stone visible from guided busway`}
				/>

				<p className="clear"> Well there is a story behind it. Elizabeth Woodcock was a market trader in Cambridge, who lived in
					Histon and travelled to and from the market on horseback. On one occasion in February 1799, she headed out of
					Cambridge but stopped off for "refreshment" at one or two of the inns on the way. After setting out again on her
					journey and reaching the area now marked by the stone, feeling slightly the worse for drink, she fell from her horse
					into a bush and was incapacitated. It started to snow and she was not noticed for around 8 days when a passer by
					saw her waving a red handkerchief from her position in a bush under the snow. She was rescued, but the experience
					did nothing good for her health and she took to her bed. She died later, not, they say from the experience of being
					in the open against the elements, but from the kindness of visitors, bringing gifts of alcohol!</p>

				<Figure
					className={`left clear max400`}
					src={require(`./images/elizabeth-woodcock-plaque.jpg`)}
					thumb={require(`./images/thumbs/elizabeth-woodcock-plaque.jpg`)}
					title={`Plaque on Elizabeth Woodcock's Cottage`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/elizabeth-woodcocks-house.jpg`)}
					thumb={require(`./images/thumbs/elizabeth-woodcocks-house.jpg`)}
					title={`Elizabeth Woodcock's Cottage in Histon`}
				/>
				<p className={`clear`}> It turns out, the stone I saw is not the original. The original can be seen in <a
					href="https://www.museumofcambridge.org.uk/" target='_blank' rel="noreferrer">the Museum of Cambridge</a>.</p>

				<Figure
					className={`left clear max400`}
					src={require(`./images/actual-elizabeth-woodcock-memorial-stone.jpg`)}
					thumb={require(`./images/thumbs/actual-elizabeth-woodcock-memorial-stone.jpg`)}
					title={`"The Original Elizabeth Woodcock Memorial Stone`}
				/>
			</>
		);
	}
};

class Giant extends React.PureComponent {
	render() {
		return (
			<>
				<Heading level={3} title={`The Histon Giant, Moses Carter`} name={`histongiant`} />

				<p>On a warm day in Histon, you may find yourself in the beer garden of The Boot and wonder why there is a large stone there.</p>

				<p><a href='https://histonandimpingtonvillagesociety.wordpress.com/history/histon/the-histon-giant/' target='_blank' rel="noreferrer">This
					website tells the story behind the Histon Giant - Moses Carter</a>.&nbsp;The giant's hat and boots can be seen in&nbsp;
				<a href='https://www.museumofcambridge.org.uk/' target='_blank' rel="noreferrer">the Museum of Cambridge</a></p>

				<Figure
					className={`max400 left`}
					src={require(`./images/histon-giant-rock.jpg`)}
					thumb={require(`./images/thumbs/histon-giant-rock.jpg`)}
					title={`The rock in the beer garden at the Boot`}
				/>

				<Figure
					className={`right max400`}
					src={require(`./images/moses-carter-hat-and-boots.jpg`)}
					thumb={require(`./images/thumbs/moses-carter-hat-and-boots.jpg`)}
					title={`The giant's hat and boots can be seen in the Museum of Cambridge`}
				/>
			</>
		);
	}
};

export default App;
